import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import PostLink from "../../components/postLink";
import Demo from "../../components/blocks/demo"

class IndexPage extends Component {
  render () {
    const data = this.props.data.wordpress;

    return (
      <Layout version="blog">
        <SEO title="Blog – Data-driven marketing automation for expanding your eCommerce store" description={data.generalSettings.description} />
        <div className="container pad-t-6 has-margin-bottom-large">
        <h1 className="post__title post__title--home">
            <span className="post__title__sub">
              <FormattedMessage id="blog.header_0" />
            </span><br />
            <span className="post__title__scroller">
              <span className="post__title__scroller__inner">
                <span>
                  <FormattedMessage id="blog.header_1" />
                </span>
                <span class="is-hidden-mobile">
                  <FormattedMessage id="blog.header_2" />
                </span>
                <span class="is-hidden-mobile">
                  <FormattedMessage id="blog.header_3" />
                </span>
                <span class="is-hidden-mobile">
                  <FormattedMessage id="blog.header_4" />
                </span>
              </span>
            </span>
          </h1>

          <div className="wrap-pad columns is-variable is-6">
            <PostLink props={data.FirstPost.edges[0].node} category={data.FirstPost.edges[0].node.categories} classes="column is-two-thirds is-full-tablet" card="featured__card featured__card--first card is-hoverable" />

            <div className="column">
            </div>
          </div>

          <div className="wrap-pad columns">
            {data.FirstPost.edges.slice(1, 4).map(post => (
              <PostLink props={post.node} category={post.node.categories} />
            ))}
          </div>
        </div>

        <Demo />
      </Layout>
    );
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wordpress {
      generalSettings {
        description
      }
      FirstPost: posts(first: 7) {
        edges {
          node {
            title
            slug
            date
            author {
              name
              slug
              UserCustom {
                avatar {
                  sourceUrl
                }
              }
            }
            categories(last: 1) {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            featuredImage {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1344, maxHeight: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      PopularPosts: posts(first: 6) {
        edges {
          node {
            title
            slug
            date
            author {
              name
              slug
              UserCustom {
                avatar {
                  sourceUrl
                }
              }
            }
          }
        }
      }
      GettingStartedPosts: category(id: 462, idType: DATABASE_ID) {
        id
        posts(last: 3) {
          edges {
            node {
              title
              slug
              date
              author {
                name
                slug
                UserCustom {
                  avatar {
                    sourceUrl
                  }
                }
              }
              featuredImage {
                altText
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1344, maxHeight: 600, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
