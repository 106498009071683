import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

import preview from "../../images/demo-preview-web.png"

const Demo = () => (
  <div className="demo container has-margin-bottom-large">
    <div className="columns is-multiline is-marginless">
      <div className="column is-full-mobile is-full-tablet is-half demo__content">
        <div className="demo__title">
          <FormattedMessage id="blog.demo_h" />
        </div>
        <div className="demo__description">
          <FormattedMessage id="blog.demo_t" />
        </div>
        <a href="/demo" className="button is-medium is-pink">
          <FormattedMessage id="navbar.request_demo" />
        </a>
      </div>
      <div className="column is-full-mobile is-full-tablet is-half demo__preview">
        <img src={preview} alt="" className="demo__image" />
      </div>
    </div>
  </div>
)

export default Demo
